/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1f26;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  /* width: 100%; */
  width: 768px;
  margin: auto;
}

/* @media only screen and (max-width: 768px) {
  .ant-layout {
    max-width: 100%;
  }
} */

@media only screen and (max-width: 768px) {
  .ant-layout {
    width: 100%;
  }
}

/* @media only screen and (max-width: 1200px) {
  main {
    max-width: 1024%;
    margin: ;
  }
} */

.square {
  width: 100%;
  margin-top: 50%;
  margin-bottom: -50%;
  /* background-color: gainsboro; */
}

.square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square-content {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 64px;
  margin-top: -64px;
  /* background-color: gainsboro; */
}

.howto-content img {
  margin: 1em;
  max-width: 80%;
  max-height: 640px;
}

.howto-content ol {
  list-style-position: inside;
  padding-left: 0;
}

.howto-content li {
  margin-bottom: 2em;
}